<template>
    <div class="flex flex-col flex-1 text-base-content">
        <AssignTenantCard :headerList="tenantsListsHeader" :showLicenseAdd="false" :getTenantsList="getTenantsList" @handleFilterResult="handleFilterResult($event)" :loadingStates="loadingStates" @selectAllTenants="selectAllTenants($event)" @handleFormSubmit="handleFormSubmit($event)" />
    </div>
</template>
<script>
import axios from "@/axios";

// import Button from "@/components/button";
import { AssignTenantCard } from "@/pages/license/components/index";

export default {
    name: "assigned-tenant",
    components: {
        // Button,
        AssignTenantCard,
    },
    props: [],

    data: () => ({
        loadingStates: {
            fetchingDetails: false,
            submit: false,
        },
        serchValues: {
            tenants: "",
        },
        tenantsList: [],
        packageId: null,
        tenantsListsHeader: [{ name: "Tenant Name" }],
    }),
    async mounted() {
        this.packageId = this.$route.params.packageName;
        await this.getTenantsData();
    },

    computed: {
        getTenantsList() {
            return this.tenantsList?.filter((el) => el.title?.toLowerCase()?.includes(this.serchValues.tenants.toLowerCase()));
        },
    },
    methods: {
        handleFilterResult({ data, type }) {
            this.serchValues[type] = data;
        },

        async getAPIDetails(section_name) {
            let apiMapper = {
                "assigned-tenant": this.getTenantsData,
            };
            (await apiMapper[section_name]) && apiMapper[section_name]();
        },
        async handleFormSubmit({ data, section }) {
            let apiMapper = {
                "license-tenants": this.assignTenants,
            };
            (await apiMapper[section]) && apiMapper[section](data);
        },

        async getTenantsData() {
            this.loadingStates.fetchingDetails = true;
            try {
                let tenantLists = await this.getAllTenant();
                let assignedTenantLists = await this.getAssignedTenants();
                tenantLists = tenantLists?.map((tenant) => {
                    for (let i of assignedTenantLists) {
                        if (i.id === tenant.id) {
                            return { ...tenant, checked: true, license_count: i.license_count };
                        }
                    }
                    return { ...tenant, checked: false, license_count: null };
                });
                this.tenantsList = tenantLists;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },

        async getAllTenant() {
            try {
                let { data } = await axios.get("/tenant");
                return data.map((el) => ({ id: el.id, title: el.title })) || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },

        async getAssignedTenants() {
            try {
                let { data } = await axios.get(`/delvium-admin/packages/tenant/${this.packageId}`);
                return data.tenants || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },
        selectAllTenants(event) {
            this.tenantsList = this.tenantsList.map((el) => ({ ...el, checked: event }));
        },
        async assignTenants() {
            this.loadingStates.submit = true;
            try {
                let payload = {
                    tenants: this.getTenantsList.filter((el) => el.checked).map((tenant) => tenant.id),
                    packages: [this.packageId],
                };
                let { data } = await axios.post(`/delvium-admin/assign/packages`, payload);
                this.$toast.success(data.message || "block assigned");
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.submit = false;
        },
    },
};
</script>
